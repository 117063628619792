import React from "react";
import Hero from "../sections/about/Hero";
import Content from "../sections/about/Content";


const PricingPage = () => {
  return (
    <>
      <Hero />
      <Content />
    </>
  );
};
export default PricingPage;
